
<template>
  <div :class="showForm ? 'invoiceViewer' : 'invoiceViewer showInvoice' ">
    <div class="query">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-8 logoArea">
            <div class="customerLogo">
              <img alt="Idea logo" src="https://s3.eu-west-2.amazonaws.com/ideatechcdn/img/idea_logo_mavi_beyaz.png">
            </div>
            <h4>e-Arşiv Fatura Görüntüleyici</h4>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-4 shadow formArea">
            <b-form-group
              label="Gönderici VKN/TCKN"
              label-for="senderInput"
              :class="{ 'form-group--error': $v.form.SenderIdentifier.$error }"
            >
              <b-form-input id="senderInput" v-model.trim="$v.form.SenderIdentifier.$model"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Alıcı VKN/TCKN"
              label-for="receiverInput"
              :class="{ 'form-group--error': $v.form.ReceiverIdentifier.$error }"
            >
              <b-form-input id="receiverInput" v-model.trim="$v.form.ReceiverIdentifier.$model"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Fatura No"
              label-for="invoiceInput"
              :class="{ 'form-group--error': $v.form.InvoiceNumber.$error }"
            >
              <b-form-input id="invoiceInput" v-model.trim="$v.form.InvoiceNumber.$model"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Fatura Tutarı"
              label-for="invoiceAmountInput"
              :class="{ 'form-group--error': $v.form.PayableAmount.$error }"
            >
              <b-form-input id="invoiceAmountInput" v-model.trim="$v.form.PayableAmount.$model"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label>{{captcha.first}} + {{captcha.second}} = <span style="color:red"> *</span>  </label>
              <input v-model="captchaInput" type="text" class="form-control">
            </b-form-group>
            <b-form-group class="text-center">
              <button @click="view()" :disabled="buttonDisabled" type="button" class="btn btn-warning w-75 btn-lg shadow-sm"><i class="fas fa-search"></i> Görüntüle</button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div class="invoice">
      <div class="container">
        <div class="row">
          <b-col cols="12" lg="2" class="invoiceHeader">
            <ShareNetwork
                network="email"
                :url="iframeSrc"
                :title="'VisionPlus E-Arşiv Fatura'"
                :description="''"
                class="btn btn-sm btn-warning"
              >
              <span><i class="fas fa-envelope"></i> Email</span>
            </ShareNetwork>
            <ShareNetwork
                network="sms"
                :url="iframeSrc"
                :title="'VisionPlus E-Arşiv Fatura'"
                :description="''"
                class="btn btn-sm btn-warning"
              >
              <span><i class="fas fa-sms"></i> SMS</span>
            </ShareNetwork>
            <ShareNetwork
                network="whatsapp"
                :url="iframeSrc"
                :title="'VisionPlus E-Arşiv Fatura'"
                :description="''"
                class="btn btn-sm btn-warning"
              >
              <span><i class="fab fa-whatsapp"></i> Whatsapp</span>
            </ShareNetwork>
            <a :href="iframeSrc" target="_blank" class="btn btn-sm btn-primary"><i class="fas fa-external-link-alt"></i> Yeni Sekmede Aç</a>
            <a :href="pdfUrl" :download="pdfUrl" class="btn btn-sm btn-primary"><i class="fas fa-file-pdf"></i> PDF İndir</a>
            <a :href="ublUrl" :download="pdfUrl" class="btn btn-sm btn-primary"><i class="fas fa-download"></i> UBL İndir</a>
            <b-button @click="newQuery()" type="button" class="btn btn-sm btn-default text-white border"><i class="fas fa-angle-double-up"></i> Yeni Sorgu</b-button>
          </b-col>
          <b-col cols="12" lg="10">
            <div class="invoiceBody">
               <!-- <vue-friendly-iframe :src="iframeSrc" @load="onLoad" @document-load="onDocumentLoad">
              </vue-friendly-iframe> -->
              <div v-html="html" class="vue-friendly-iframe">

              </div>
              <!-- <iframe :src="iframeSrc" frameborder="0"></iframe> -->
            </div>
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { required, helpers } from 'vuelidate/lib/validators'
const alpha = helpers.regex('alpha', /^[0-9]*\.?[0-9]*$/)
const idValidation = (value) => {
  return value.toString().length === 10 || value.toString().length === 11
}
const invoiceValidation = (value) => value.length === 16

export default {
  name: 'Home',
  data () {
    return {
      form: {
        InvoiceNumber: null,
        PayableAmount: null,
        ReceiverIdentifier: null,
        SenderIdentifier: null
      },
      captcha: {
        first: null,
        second: null
      },
      captchaInput: null,
      buttonDisabled: true,
      showForm: true,
      iframeSrc: '',
      pdfUrl: '',
      ublUrl: '',
      loaded: true,
      html: null
    }
  },
  validations () {
    const validations = {
      form: {
        InvoiceNumber: {
          required,
          invoiceValidation
        },
        ReceiverIdentifier: {
          required,
          idValidation
        },
        SenderIdentifier: {
          required,
          idValidation
        },
        PayableAmount: {
          required,
          alpha
        }
      }
    }
    return validations
  },
  mounted () {
    this.captcha.first = Math.ceil(Math.random() * 10)
    this.captcha.second = Math.ceil(Math.random() * 10)
  },
  components: {
  },
  computed: {
  },
  methods: {
    newQuery () {
      this.showForm = true
    },
    onLoad () {
      this.loaded = false
    },
    onDocumentLoad () {
      this.loaded = false
    },
    async postData (url = '', data = {}) {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
      })
      if (response.ok) {
        return response.json()
      } else {
        response.json().then((res) => {
          this.$toasted.show(res, {
            type: 'error', duration: 35000, keepOnHover: true, position: 'top-right', theme: 'bubble'
          })
          return false
        })
      }
    },
    async getData (url = '') {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      })
      return response.json()
    },
    view () {
      this.$v.form.$touch()
      if (this.$v.form.$error) {
        this.$toasted.show('Lütfen tüm alanları eksiksiz doldurunuz.', {
          type: 'error', duration: 35000, keepOnHover: true, position: 'top-right', theme: 'bubble'
        })
      } else {
        this.$toasted.show('Sorgulanıyor, lütfen bekleyin.', { type: 'info', keepOnHover: true, theme: 'bubble', duration: '3000' })
        this.postData('https://efuintapi.ideateknoloji.com.tr/Api/EInvoice/GetEInvoiceUrl', { ...this.form })
          .then(result => {
            if (result) {
              this.$toasted.show('Faturanız yükleniyor.', {
                type: 'success', duration: 3500, keepOnHover: true, theme: 'bubble'
              })
              this.iframeSrc = `https://efuintapi.ideateknoloji.com.tr/Api/EInvoice/GetInvoiceForHtml?openId=${result.EArchiveHtmlToken}`
              this.html = this.httpGet(this.iframeSrc)
              this.pdfUrl = result.EArchivePdfUrl
              this.ublUrl = result.EArchiveUblUrl
              this.showForm = false
            }
          })
      }
    },
    httpGet (theUrl) {
      var xmlHttp = new XMLHttpRequest()
      xmlHttp.open('GET', theUrl, false) // false for synchronous request
      xmlHttp.send(null)
      return xmlHttp.responseText
    }
  },
  watch: {
    captchaInput (e) {
      if (e) {
        if (Number(e) === Number(this.captcha.first) + Number(this.captcha.second)) {
          this.buttonDisabled = false
        } else {
          this.buttonDisabled = true
        }
      } else {
        this.buttonDisabled = true
      }
    }
  }
}
</script>
<style lang="sass">
.form-group--error
  color: #ff0000 !important
  & legend
    color: #ff0000 !important
  .form-control,
  .vs__dropdown-toggle
    border: 1px #ff0000 solid !important
    background: #ff000005 !important

.invoiceViewer
  width: 100%
  height: 200vh
  overflow: hidden
  position: fixed
  .form-group
    margin-bottom: 1rem
  .logoArea
    padding: 2.5rem
    text-align: center
  .formArea
    background: #fff
    padding: 2.5rem
    border-radius: 5px
  & h4
    width: 60%
    margin: 20px auto
    font-weight: 500
    color: #fff
    letter-spacing: -0.02em
  & label
    font-weight: 400
    letter-spacing: -0.3px
    margin: 0px
    padding: 0px
  & button
    font-weight: 700
  .customerLogo
    padding-top: 100px
    width: 100%
    text-align: center
    & img
      max-height: 160px
      max-width: 160px
  @media (max-width: 1280px)
    .customerLogo
      & img
        max-height: 100px
        max-width: 100px
  @media (max-width: 992px)
    padding-top: 0px
    .logoArea
      padding: 1rem 30px
    .formArea
      padding-top: 10px
      border-radius: 0px
    & h4
      width: 100%
      margin: 10px auto
    .customerLogo
      padding-top: 0px
      width: 100px
      float: left
      & img
        max-width: 70px
        max-height: 70px
  @media (max-width: 468px)
    overflow: auto
    padding-top: 0px
    .logoArea
      padding: 5px 30px 0 30px
    .formArea
      padding: 10px 15px
    & h4
      font-size: 18px
    .customerLogo
      padding-top: 0px
      width: 80px
      float: left
      & img
        max-width: 70px
        max-height: 70px
  .query
    background: linear-gradient(45deg, #003088, #5cb3e6)
    background-size: cover
    margin: auto
    height: 100vh
    padding-top: 100px
  .logoArea
    padding: 2.5rem
    text-align: center

  .formArea
    background: #fff
    padding: 2.5rem
    border-radius: 5px

  h4
    width: 60%
    margin: 20px auto
    font-weight: 500
    color: #fff
    letter-spacing: -0.02em

  label
    font-weight: 400
    letter-spacing: -.3px
    margin: 0
    padding: 0

  button
    font-weight: 700

  .customerLogo
    padding-top: 100px
    width: 100%
    text-align: center

    img
      max-height: 160px
      max-width: 160px
@media (max-width: 1280px)
  .query
    .customerLogo img
      max-height: 100px
      max-width: 100px

@media (max-width: 992px)
  .query
    padding-top: 0 !important

    .logoArea
      padding: 1rem 30px

    .formArea
      padding-top: 10px
      border-radius: 0

    h4
      width: 100%
      margin: 10px auto

    .customerLogo
      padding-top: 0
      width: 100px
      float: left

      img
        max-width: 70px
        max-height: 70px

@media (max-width: 468px)
  .query
    overflow: auto
    padding-top: 0 !important
    .logoArea
      padding: 5px 30px 0 30px
    .formArea
      padding: 10px 15px
    h4
      font-size: 18px
    .customerLogo
      padding-top: 0
      width: 80px
      float: left
      img
        max-width: 70px
        max-height: 70px
.showInvoice
  margin-top: -100vh
  margin-top: -100vh
.invoiceViewer
  transition: 0.6s
  -webkit-transition: .6s

.invoice
  background: linear-gradient(-45deg, #5cb3e6, #003088)
  background-size: cover
  width: 100%
  height: 100vh
  position: relative
  overflow: hidden
  .invoiceHeader
    padding-top: 100px
    .btn
      display: block !important
      float: none !important
      width: 100%
      margin-bottom: 5px
    @media (max-width: 1023px)
      .btn
        width: fit-content !important
        float: left !important
        display: inline-block !important
        margin-right: 0.5rem
  .invoiceBody
    width: 21cm
    height: 100vh
    display: block
    overflow: auto
    margin: auto
    @media (max-width: 1023px)
      width: 100%
      height: 80vh
  #viewerContainer
    background: #fff
    width: 100%
    height: 100%
  .vue-friendly-iframe
    width: 21cm
    height: 98vh
    display: block
    overflow: auto
    margin: 1vh auto
    background: white
    & iframe
      width: 21cm
      height: 100%
      border: transparent

</style>
